







































import { Component, Vue } from 'vue-property-decorator'
import PageLayout from '@/components/common/page-layout/PageLayout.vue'
import ContentArea from '@/components/common/ContentArea.vue'
import Outcome from '@/components/Outcome.vue'
import { LocaleMessages } from 'vue-i18n'
import moment from 'moment'
import PrimaryButton from '@/components/common/PrimaryButton.vue'

interface IText {
  title: LocaleMessages | string
  description: LocaleMessages | string
  completeDate: string
  completedAtTime: string
}
@Component({
  components: {
    PageLayout,
    ContentArea,
    Outcome,
    PrimaryButton
  }
})
export default class Negative extends Vue {
  private userInfo:any
  private outcomeContent: IText = {
    title: '',
    description: '',
    completeDate: '',
    completedAtTime: ''
  }

  get completeDate (): string {
    const date = this.$store.state.walkthrough.test_completion_time
    return moment(date).format('MM/DD/YYYY') || ''
  }

  get completeTime (): string {
    const date = this.$store.state.walkthrough.test_completion_time
    return moment(date).format('h:mm A') || ''
  }

  get expiredTime (): string {
    const date = this.$store.state.walkthrough.test_validity_expiration_time
    return moment(date).format('MM/DD/YYYY') || ''
  }

  async created (): Promise<void> {
    // this.$store.dispatch('auth/deleteResultData')
    // this.$store.dispatch('walkthrough/completeTest', 'test_result=3')
    this.userInfo = await this.$store.dispatch('auth/getUserData')
    console.log('userInfo', this.userInfo)
    this.outcomeContent.title = this.userInfo?.previousResult?.full_name
    const date = this.userInfo?.previousResult?.completed_at
    this.outcomeContent.completeDate = moment(date).format('MM/DD/YYYY') || ''
    this.outcomeContent.completedAtTime = this.userInfo?.previousResult?.completed_at_time
    this.outcomeContent.description = '<b>' + this.$t('outcome.negative.passport_number') + ': </b><br>' + this.userInfo?.passportNumber.toUpperCase()
  }

  private share ():void {
    window.print()
  }

  private destroyed (): void {
    this.$store.dispatch('auth/setDemographic', false)
  }
}
